.feedback div{
    padding: 4px;
}
.feedback .like{
    display: flex;
    justify-content: space-around;
    width: 100%;
}
.feedback .btn-submit{
background: linear-gradient(266.57deg, #b6ffe5 -4.01%, #4eeeb3 89.75%);
  border-radius: 10px;
  width: 200px;
  height: 50px;
  border: none;
  color: #102691;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
  
}