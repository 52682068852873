.hero-section .row {
  justify-content: space-between;
  z-index: 10;
  position: relative;
  align-items: center;
}
.hero-section .column {
  width: 50%;
}

/* left  */
.hero--section--left .title {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 66px;
  color: #102691;
  position: relative;
  z-index: 999;
  margin: 15px 0;
}
.hero--section--left .responsive--circle .hero-circle {
  display: none;
}

.hero--section--left p {
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #00072c;
  position: relative;
  z-index: 1;
}

.hero--section--left .hero--sec--btns .btn {
  background: linear-gradient(266.57deg, #b6ffe5 -4.01%, #4eeeb3 89.75%);
  border-radius: 10px;
  width: 200px;
  height: 50px;
  border: none;
  color: #102691;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
}
.hero--sec--btns .btn.l a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.hero--sec--btns .btn.l a svg {
  margin-left: 10px;
}
/* .hero--sec--btns .btn a {
  color: #102691;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
} */
.hero--section--left .hero--sec--btns .btn.about-pfi {
  background-image: none;
}
.hero--sec--btns .btn.about-pfi {
  color: #102691 !important;
  text-underline-offset: 5px;
  text-decoration: underline;
}

/*  */
/* right */

.hero--section--right {
  /* background-image: url("../../assets/images/hero-Vector.png"); */
  /* background: #bfffe8;
  filter: blur(200px); */
  position: relative;
}
.hero--section--right::before {
  content: " ";
  position: absolute;
  width: 782px;
  height: 600px;
  left: 0;
  top: 0;
  right: 0;
  background: #bfffe8;
  filter: blur(227px);
}

.hero--section--right img {
  width: 100%;
  z-index: 19999;
  position: relative;
}

/* second container */

.hero--second--row {
  background: linear-gradient(180deg, #000000 0%, #000b40 100%);
  border: 1px solid #000000;
  border-radius: 10px;
  padding: 50px;
  position: relative;
}
.hero--second--row::after {
  background-image: url("../../assets/images/hero-wave.png");
  content: "";
  position: absolute;
  width: 660px;
  height: 107px;
  opacity: 0.6;
  right: 0;
  bottom: 0;
}

.hero--second--row img.circle {
  height: 234px;
}

.hero--second--row .hero--sec--right {
  margin-left: 100px;
}

.hero--sec--right .sub-tit {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 38px;
  margin: 0;

  background: linear-gradient(90deg, #26d878 0%, #50efb6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  /* text-fill-color: transparent; */
}

.hero--sec--right p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  color: #d9d9d9;
}

button.show-detail {
  background: transparent;
  padding: 0;
  border: none;
}

/* .hero--sec--right .show-detail {
  background: linear-gradient(90deg, #26d878 0%, #50efb6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  border: none;
}
*/
.hero--sec--right .show-detail a {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  border: none;
  text-decoration: underline;
  color: #26d878;
  text-underline-offset: 5px;
}

.about--login--responsive {
  display: none;
}
/* .hero--second--row */

@media screen and (max-width: 1600px) {
  .hero--section--right::before {
    left: auto;
  }
}

@media screen and (max-width: 1200px) {
  .hero--section--right::before {
    width: 600px;
    height: 500px;
    background: #bfffe8;
    filter: blur(126px);
  }
  .hero--section--left .title {
    font-size: 46px;
    line-height: normal;
  }
  .hero--second--row .hero--sec--right {
    margin-right: 50px;
  }
  /* 
  .hero-section .column.hero--section--left {
    width: 60%;
  }
  .hero-section .column.hero--section--right {
    width: 40%;
  } */
}

@media screen and (max-width: 992px) {
  .hero-section .column.hero--section--left {
    /* width: 60%; */
    margin-top: 80px;
  }
  /* .hero-section .column.hero--section--right {
    width: 40%;
  } */
  .hero--section--left .hero--sec--btns .btn {
    position: relative;
    z-index: 1;
  }
  .hero--second--row .hero--sec--right {
    margin-left: 50px;
    margin-right: 30px;
  }

  .hero-section .hero--second--row {
    margin-top: 80px;
  }
  .hero--sec--right .sub-tit {
    font-size: 23px;
    line-height: normal;
  }

  /* .hero--second--row .hero--second--img img {
    width: auto;
    max-width: 100%;
    height: auto;
  } */

  .hero--second--row img.circle {
    height: 190px;
  }
}

@media screen and (max-width: 860px) {
  .hero--section--right::before {
    width: 381px;
    background: #bfffe8;
    filter: blur(65px);
    top: 0;
  }
  .hero--section--left .title {
    font-size: 35px;
  }
}

@media screen and (max-width: 768px) {
  .hero--section--right::before {
    width: 381px;
    background: #bfffe8;
    filter: blur(65px);
    top: -67px;
    right: -20px;
  }
  .hero--section--left .title {
    font-size: 30px;
  }
  .hero--section--left .title br {
    display: none;
  }
  .hero--section--left .hero--sec--btns .btn {
    width: 170px;
  }
  .hero--section--left .hero--sec--btns .btn.about-pfi {
    width: 120px;
  }

  .hero--second--row .hero--sec--right {
    margin-left: 0px;
    margin-right: 0px;
  }
  .hero--second--row img.circle {
    height: 160px;
    margin-right: 30px;
  }
}

@media screen and (max-width: 640px) {
  .hero-section .hero--section--right {
    display: none;
  }
  .hero-section .column.hero--section--left {
    width: 100%;
  }
  .hero-section .hero--section--left {
    font-size: 36px;
    text-align: center;
  }
  /* .hero--section--left .hero--sec--btns {
    display: flex;
  } */
  .hero--section--left .hero--sec--btns .btn {
    width: 100%;
  }
  .hero--section--left .hero--sec--btns .btn.about-pfi {
    display: none;
  }
  .about--login--responsive {
    display: block;
    margin-top: 20px;
    margin-bottom: 40px;
  }
  .hero--section--left .about--login--responsive .login--btn {
    height: 50px;
    width: 100%;
    position: relative;
    border: none;
    background: transparent;
  }
  .about--login--responsive .login--btn a {
    cursor: pointer;
    font-family: "Inter", sans-serif;
    font-size: 17px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 10px;
    color: #102691;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    text-decoration: none;
    position: relative;
    z-index: 11111;
  }
  .about--login--responsive .login--btn::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    padding: 1px;
    background: linear-gradient(266.57deg, #3479fb -4.01%, #4eeeb3 89.75%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }
  .hero-section .hero--first--row {
    position: relative;
    padding-top: 100px;
  }
  .hero-section .hero--first--row::after {
    position: absolute;
    width: 782px;
    height: 689px;
    left: 145px;
    top: -89px;
    background: #bfffe8;
    filter: blur(227px);
    content: "";
  }
  .hero-section {
    overflow: hidden;
    margin-top: -70px;
  }
  .hero-section .hero--second--row {
    flex-direction: column;
    padding: 100px 0;
  }
  .hero-section .hero--second--row::before {
    background-color: #000000;
  }
  .row.hero--second--row::before {
    background: linear-gradient(180deg, #000000 0%, #000b40 100%);
    position: absolute;
    inset: 0;
    height: 103%;
    content: "";
    z-index: -2;
    margin-left: -37px;
    margin-right: -37px;
    margin-top: -2px;
    margin-bottom: -5px;
  }

  .col.hero--second--img {
    text-align: center;
  }
  .hero--second--row img.circle {
    height: fit-content;
    margin-right: 0;
    width: 90%;
  }
  .hero--second--row .hero--sec--right {
    text-align: center;
    margin-top: 50px;
  }
  .hero--second--row::after {
    width: 375px;
    height: 107px;
    right: -30px;
  }

  .hero--section--left .responsive--circle .hero-circle {
    display: block;
    height: 120px;
  }
  .hero--section--left .responsive--circle {
    /* width: 100%;
    float: right;
    text-align: end;
    text-align: -webkit-right; */
    position: relative;
  }
  .hero--section--left .responsive--circle .hero-circle {
    /* z-index: 1111111;
    position: relative;
    text-align: end; */
    z-index: 1111111;
    position: relative;
    text-align: end;
    position: absolute;
    right: 0;
    top: -66px;
  }
  .hero--section--left .title {
    font-size: 36px;
  }
}

.hero--sec--btns {
  margin-top: 40px;
}