.openmodal--sec--btns .btn {
  background: linear-gradient(266.57deg, #b6ffe5 -4.01%, #4eeeb3 89.75%);
  border-radius: 6px;
  width: 150px;
  height: 35px;
  border: none;
  color: #102691;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-decoration: none;
}
.openmodal--sec--btns .btn.l a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.openmodal--sec--btns .btn.l a svg {
  margin-left: 10px;
}

.openmodal--sec--header {
  color: #102691;
}

.openmodal--sec--subheader {
  color: #0e0f14c7;
  font-size: 1rem !important;
  font-weight: 200 !important;
}

.openmodal--sec--body {
  color: #0e0f14c7;
  margin-bottom: 16px !important;
  font-size: 0.7rem !important;
  font-weight: 100 !important;
}
