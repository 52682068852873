body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --color: "orange";
}
.row {
  display: flex;
}

.loader {
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: flex;
  position: fixed;
  inset: 0;
  background: white;
  z-index: 9999999;
  overflow: hidden;
}
.loader img {
  width: 250px;
}


