.team-section .card {
    border: 2px solid transparent;
    border-radius: 15px;
    background: linear-gradient(white, white) padding-box, linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%) border-box;
    height: 100%;
}

.team-section .pic {
    border: 5px solid transparent;
    border-radius: 168px;
    background: linear-gradient(white, white) padding-box, linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%) border-box;
    margin: 32px auto;
}

.team-section .pic img {
    border-radius: 168px;
}

.team-section .content-container {
    width: fit-content;
    margin: 0 auto;
}

.team-section .name {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.team-section .designation {
    color: #00072C;
    text-align: center;
    font-family: Inter;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    background: linear-gradient(267deg, #3479FB -4.01%, #4EEEB3 89.75%);
    padding: 4px 30px;
    border-radius: 10px;
}

.team-section .content {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 22px;
    margin-right: 16px;
    margin-left: 16px;
}