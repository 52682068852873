.faq h1 {
    text-align: center;
}

.faq {
    display: block;
    /* vertical-align: middle; */
}

.MuiAccordion-root {
    border-radius: 10px;
    margin: 10px;
}
