.Personalfinancial .row {
  justify-content: space-between;
  align-items: center;
}

.personal--fin--box {
  /* margin: 90px 0; */
}
.personal--financiall--pages {
  position: relative;
  /* margin-top: 100px; */
}
.Personalfinancial::before {
  position: absolute;
  width: 780px;
  height: 600px;
  background: #bfffe8;
  filter: blur(227px);
  content: "";
  right: 0;
  bottom: 150px;
  z-index: -1;
}

.Personalfinancial .per--title {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 45px;
  line-height: 54px;
  margin: 0;
  margin-right: 20px;
  color: #102691;
}

.Personalfinancial .p--col {
  width: 50%;
}

.Personalfinancial .p--col.right img {
  width: 100%;
}

.Personalfinancial .specil--btn {
  display: flex;
  justify-content: space-between;
  margin: 40px 0;
  flex-wrap: wrap;
}
.Personalfinancial .specil--btn button {
  border: 1px solid #00072c;
  border-radius: 40px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #00072c !important;
  padding: 0.7rem 1.4rem;

  background: white;
}

.Personalfinancial p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #00072c;
}
.Personalfinancial .perosal--sec--btns .btn {
  background: linear-gradient(266.57deg, #b6ffe5 -4.01%, #4eeeb3 89.75%);
  border-radius: 10px;
  width: 200px;
  height: 50px;
  border: none;
  color: #102691;
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-decoration: none;
}
.perosal--sec--btns .btn a {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
}
.perosal--sec--btns .btn a svg {
  margin-left: 10px;
}

/* bottom */

.personal--fin--box {
  position: relative;
}
.personal--fin--box .per--fin--box--col--left {
  width: 40%;
  padding-right: 35px;
}
.personal--fin--box .per--fin--box--col--right {
  width: 60%;
}
.personal--fin--box {
  background: linear-gradient(180deg, #000000 0%, #000b40 100%);
  box-shadow: 0px 4px 24px rgba(187, 187, 187, 0.25);
  border-radius: 10px;
  padding: 50px 40px;
  padding-right: 15px;
}
.per--fin--box--col--left .titl--work {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 38px;
  margin: 0;
  background: linear-gradient(90deg, #26d878 0%, #50efb6 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.per--fin--box--col--left p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
}

.per--fin--box--col--left .per--try--to--free {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  text-decoration: underline;
  color: #26d878;
  text-underline-offset: 5px;
}

.Personalfinancial .p--col.right .imgcircleforresponseive {
  display: none;
}
.try--for--free--responsive {
  display: none;
}

@media screen and (max-width: 1200px) {
  .Personalfinancial .per--title {
    font-size: 40px;
    line-height: normal;
  }
  .Personalfinancial .specil--btn {
    margin: 30px 0;
  }
  .Personalfinancial .specil--btn button {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 992px) {
  .Personalfinancial .specil--btn button {
    padding: 10px 15px;
    margin-right: 22px;
    margin-bottom: 22px;
  }
  .Personalfinancial .specil--btn {
    margin-bottom: 20px;
    justify-content: flex-start;
  }
}
@media screen and (max-width: 860px) {
  .Personalfinancial .p--col.right {
    margin-top: 50px;
  }
  .Personalfinancial .per--title {
    font-size: 35px;
  }
  .Personalfinancial .specil--btn button {
    font-size: 15px;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .Personalfinancial .specil--btn {
    margin-bottom: 10px;
  }
  .Personalfinancial p {
    margin: 0;
    padding-bottom: 10px;
  }
}

@media screen and (max-width: 700px) {
  /* .Personalfinancial .row {
    flex-direction: column;
  } */
  .Personalfinancial .p--col {
    width: 100%;
  }
  .personal--financiall--pages {
    margin-top: 0;
  }
  .Personalfinancial .p--col.right img.pfindexcircle {
    display: none;
  }
  .Personalfinancial .p--col.right .imgcircleforresponseive {
    display: block;
  }
  .Personalfinancial .p--col.right img {
    width: auto;
    margin: auto;
    max-width: 100%;
  }
  .Personalfinancial .index----.row {
    flex-direction: column;
  }
  .Personalfinancial .p--col.right {
    order: -1;
  }
  .Personalfinancial .per--title {
    font-size: 26px;
    line-height: normal;
  }
  .Personalfinancial .specil--btn button {
    font-size: 13px;
    margin-bottom: 10px;
    margin-right: 10px;
  }
  .Personalfinancial .perosal--sec--btns .btn {
    width: 100%;
    margin-top: 10px;
  }
}

@media screen and (max-width: 640px) {
  .how--its--work {
    padding-left: 0 !important;
    padding-right: 0 !important;
    border-radius: 0;
  }
  .personal--fin--box {
    border-radius: 0;
  }

  .try--for--free--responsive {
    display: block;
    width: 100%;
    margin-top: 50px;
  }

  .try--for--free--responsive .login--btn {
    height: 50px;
    width: 100%;
    position: relative;
    border: none;
    background: transparent;
  }
  .try--for--free--responsive .login--btn a {
    cursor: pointer;
    font-family: "Inter", sans-serif;
    font-size: 17px;
    font-weight: 600;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: center;
    border-radius: 10px;
    color: #102691;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    position: relative;
    z-index: 11111;
    background: linear-gradient(90deg, #26d878 0%, #50efb6 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
  .try--for--free--responsive .login--btn::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    padding: 1px;
    background: linear-gradient(266.57deg, #3479fb -4.01%, #4eeeb3 89.75%);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  .personal--fin--box {
    /* margin: 90px 0; */
    margin-bottom: 0;
  }
  .personal--fin--box {
    padding-bottom: 100px;
  }
}
