.team-section{
    margin-bottom: 87px;
}

.team-section .title {
    text-align: center;
    font-family: Inter;
    font-size: 40px;
    font-style: normal;
    font-weight: 600;
    line-height: 66px;
    color: #2C70FC;
}

.team-section .divider {
    border-color: rgba(0, 0, 0, 0.20);
}

