.number span {
  cursor: pointer;
}
/* .number{
    margin:100px;
} */
.number > .minus {
  width: 42px;
  height: 40px;
  background-image: linear-gradient(90deg, #26D878 0%, #50EFB6 100%);
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
  margin-right: 0px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.currencyNumber > .minus {
  margin-right: -13px !important;
}

.number > .plus {
  width: 42px;
  height: 40px;
  background-image: linear-gradient(90deg, #26D878 0%, #50EFB6 100%);
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
}
.number input {
  height: 38px;
  width: 200px;
  text-align: center;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
  border: 1px solid #26D878;
  display: inline-block;
  vertical-align: middle;
  color: #00072C;
  padding-top: 0px;
  padding-bottom: 0px;
}

.number > .currency {
  display: inline-block;

  /* 
    height:30px;
    width: 20px;
    padding-top: 6px;
    padding-left: 6px;
    vertical-align: middle;
    text-align: center;
    border:1px solid #B0FEE2;
    border-right:1px solid #ffffff;
    */
}
.number > .currency > .currencySign {
  position: relative;
  margin: 0px;
  top: 3px;
  left: 17px;
  font-size: 16px;
  font-family: "Inter", sans-serif;
  font-weight: 500; 
}
.number {
  width: fit-content;
}
