.fade_rule {
    height: 1px;
    background-color: #E6E6E6;
    width: 100%;
    margin: 0 auto;
    opacity: 0.5;
    background-image: linear-gradient(to right , white 2%, #E6E6E6 50%, white 98%);
    background-image: -o-linear-gradient(to right , white 2%, #E6E6E6 50%, white 98%);
    background-image: -moz-linear-gradient(to right , white 2%, #E6E6E6 50%, white 98%);
    background-image: -webkit-linear-gradient(to right , white 2%, #E6E6E6 50%, white 98%);
    background-image: -ms-linear-gradient(to right , white 2%, #E6E6E6 50%, white 98%);
    background-image: -webkit-gradient( linear, to right to top, to left to top, color-stop(0.02, white), color-stop(0.5, gray), color-stop(0.98, white) );
}

button a {
    text-decoration: none !important;
}